.alert-container {
  position: fixed;
  width: 335px;
  height: auto;
  top: 0;
  right: 0;
  z-index: 9999;
}

.cheers-holder {
  border: 1px solid black;
  width: 320px;
  min-height: 67px;
  max-width: 320px;
  border-color: transparent;
  -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  display: block;
  z-index: 9998;
  clear: both;
  cursor: pointer;
}

.cheers-holder.success {
  background-color: #43ac6a;
}

.cheers-holder.error {
  background-color: #f04124;
}

.cheers-holder.warning {
  background-color: #e99002;
}

.cheers-holder.info {
  background-color: #39accd;
}

.cheers-holder.success .cheers-icon {
  background-color: #289852;
}

.cheers-holder.error .cheers-icon {
  background-color: #c6240a;
}

.cheers-holder.warning .cheers-icon {
  background-color: #ba7200;
}

.cheers-holder.info .cheers-icon {
  background-color: #1993b6;
}

.cheers-icon {
  height: 45px;
  width: 45px;
  float: left;
  color: white;
  font-size: 30px;
  text-align: center;
  transition: 0.5s;
  margin: 10px 0 10px 10px;
}

.cheers-icon i {
  transition: 0.5s;
  margin-top: 8px;
}

.cheers-holder .cheers-body {
  height: auto;
  margin: 10px 10px 10px 0;
  width: 245px;
  float: right;
  font-weight: normal;
  font-size: 14px;
  transition: 0.5s;
}

.cheers-holder .cheers-title {
  font-weight: 600;
  transition: 0.5s;
  font-size: 14px;
}

.cheers-holder.slideleft {
  margin-top: 15px;
  margin-left: 370px;
}

.cheers-holder.fadein {
  margin-top: 15px;
  margin-right: 15px;
  opacity: 0;
}
